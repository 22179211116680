
import React from "react";

export function Home() {
    return (
        <>
            <h2>Home</h2>
            <p>
                This is the supervisor scraper frontend
            </p>
        </>
    );
}